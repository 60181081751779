@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-background;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  padding: 24px 40px 24px 40px;
  gap: 18px;

  .buttonBox {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .editCancel {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      color: $color-primary;
      background-color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }

    .cancel {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      color: $color-gray-2;
      background-color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }

    .save {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      background-color: $color-gray-4;
      color: $color-gray-2;
      min-width: 150px;
      min-height: 42px;
    }

    .editSave {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      background-color: $color-primary;
      color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }
  }

  .titleContainer {
    padding: 16px, 0px, 8px, 0px;

    .title {
      font-family: $typography-primary-extra-light-font-family;
      width: max-content;
      height: 100%;
      border-radius: 4%;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
  }
}
