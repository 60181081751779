@import '../../styles';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: $color-background;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  padding: 24px 40px 24px 40px;
  gap: 18px;
}
