@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .date {
      @extend .row;
    }

    .hiddenDate {
      @extend .row;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .buttonItem,
  .calendarActive {
    padding: 5px;
    margin: 0;
    border: 0;
    background-color: transparent;
    color: $color-secondary;
    font-family: $typography-tertiary-ubuntu-regular-font-family;
    font-size: $typography-secondary-font-size;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: max-content;
    width: max-content;
  }

  .calendarActive {
    background-color: #f2e6de !important;
    color: $color-primary !important;
  }
  .textInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px, 0px, 0px, 0px;
    gap: 8px;

    .title {
      color: $color-head-1;
      font-family: $typography-primary-extra-light-font-family;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
    .description {
      color: $color-gray-dark;
      line-height: 24px;
      font-size: $typography-primary-font-size;
      font-weight: $typography-primary-font-weight;
      letter-spacing: 0.15px;
    }
  }

  .FieldsContainer {
    display: flex;
    gap: 40px;

    .column {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 21px;
    }
  }
}
