@import '../../styles';

.button {
  color: $color-white !important;
  background-color: $color-primary !important;
  z-index: 1;

  .icon {
    position: relative;
    right: 4px;
    width: 19px;
  }

  .text {
    white-space: nowrap;
    font-family: $typography-tertiary-ubuntu-regular-font-family !important;
  }
}

.button:hover {
  background-color: $color-primary-light;
  color: $color-white;
}
.button:disabled {
  background-color: $color-primary-light;
  color: $color-gray-3 !important;
}
