@import 'src/styles/index';

.projectBox {
  $timeline-width: 372px;
  $sidebar-width: calc($timeline-width + 1px);

  overflow: hidden;
  width: 100%;

  .react-calendar-timeline {
    background-color: $color-gray-extra-light;
    border: 1px solid $color-gray-4;
    border-bottom: none;
    border-radius: 4px 4px 0 0;

    .rct-header-root {
      background-color: $color-gray-extra-light;
      border: 1px solid $color-gray-4;

      > div {
        width: $timeline-width !important;
      }

      .header {
        height: 100%;

        .cell {
          display: flex;
          align-items: center;
          padding: 0 16px;
          border-right: 1px solid $color-gray-6;
          color: $color-black-text;

          .title {
            text-align: center;
            font-family: $typography-tertiary-ubuntu-regular-font-family;
            text-transform: none;
            font-size: 0.875rem;
            opacity: 0.38;
          }
        }
      }

      .rct-calendar-header {
        border: none;
        width: calc(100% - $timeline-width) !important;
        font-family: $typography-tertiary-ubuntu-regular-font-family !important;
        font-size: $typography-secondary-font-size;
        background-color: $color-gray-extra-light;
        color: $color-gray-dark;

        div {
          left: -2px;
          border-left: 1px dashed $color-gray-6;
          font-family: $typography-tertiary-ubuntu-regular-font-family;
          font-size: $typography-secondary-font-size;
          background-color: $color-gray-extra-light;
        }

        .weekInterval {
          justify-content: unset;
          border: 0;
          border-left: 1px dashed $color-gray-6;

          .week {
            position: absolute;
            top: 3px;
            left: 0;
            width: min-content;
            padding: 1px 2px;
            border: none;
            background-color: $color-gray-4;
            color: $color-gray-2 !important;
            font-weight: $typography-secondary-font-weight;
            font-size: $typography-accent-font-size;
          }

          .month {
            margin: 0 auto;
            border: none;
          }
        }

        .weekInterval.current {
          background-color: $color-white !important;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 3px;
            background-color: $color-primary;
          }

          div {
            background-color: $color-white !important;
          }

          .week {
            background-color: $color-gray-5 !important;
          }
        }

        .dayInterval {
          background-color: $color-gray-extra-light;
          border-left: 1px solid $color-gray-4;
          font-size: $typography-accent-font-size;
        }

        .dayInterval.firstDay {
          border-left: 1px dashed $color-gray-6;
        }

        .dayInterval.currentWeek {
          background-color: $color-white !important;
        }

        .dayInterval.currentDay {
          color: $color-primary;
        }
      }
    }

    .rct-outer {
      position: relative;

      .rct-sidebar {
        width: $sidebar-width !important;
        height: 100% !important;

        .rct-sidebar-row {
          width: $sidebar-width !important;
          padding: 0;
          border-bottom: none;
          font-family: $typography-tertiary-ubuntu-medium-font-family;
          font-weight: $typography-primary-font-weight;
          font-size: $typography-secondary-font-size;
          background: $color-white;
          color: $color-head-1;

          .header {
            height: 100%;
            margin-left: 1px;

            .cell {
              display: flex;
              align-items: center;
              border-right: 1px solid $color-gray-6;
              color: $color-black-text;

              > div {
                width: 100%;
              }

              .futureAllocation {
                width: 100%;
                text-align: center;
                font-family: $typography-tertiary-ubuntu-light-font-family;
                text-transform: none;
                font-size: 0.875rem;
                opacity: 0.87;
              }
            }
          }
        }

        .subGroup,
        .newSubGroup {
          justify-content: right;
          width: 100%;
          text-align: right;
          padding: 0 16px;
          margin-top: 6px;
          line-height: 28px;
          font-family: $typography-tertiary-ubuntu-light-font-family;
          font-size: 16px;
        }

        .newSubGroup {
          background-color: #f5f5f5;
        }

        .rct-sidebar-row-even {
          background: $color-white;
        }

        .rct-sidebar-row-odd {
          background: $color-white;
        }
      }

      .rct-scroll {
        border-left: none;
        position: absolute !important;

        .rct-vertical-lines {
          .rct-vl {
            border: none;
            border-left: 1px dashed $color-gray-4;
            height: 100% !important;
            background: $color-white;
          }

          .rct-day-6 {
            background: repeating-linear-gradient(
              135deg,
              $color-gray-extra-light,
              $color-gray-4 1px,
              $color-gray-extra-light 4px,
              $color-gray-extra-light 5px
            );
          }

          .rct-day-0 {
            background: repeating-linear-gradient(
              135deg,
              $color-gray-extra-light,
              $color-gray-4 1px,
              $color-gray-extra-light 4px,
              $color-gray-extra-light 5px
            );
            border-right: 1px dashed $color-gray-6;
          }
        }

        .rct-horizontal-lines {
          .rct-hl-odd {
            border-bottom: none;
            background: $color-white;
          }

          .rct-hl-even {
            border-bottom: none;
            background: $color-white;
          }
        }

        .rct-items {
          border-bottom: none;
        }
      }
    }
  }

  .user {
    padding-left: 16px;
    font-family: $typography-tertiary-ubuntu-light-font-family;
    color: $color-black-text;
    border-bottom: 1px solid $color-gray-4;
  }

  .item,
  .subItem,
  .newItem {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: flex;
    opacity: 0.8;
    font-family: $typography-tertiary-ubuntu-light-font-family;
    font-size: 10px;
    border-radius: 4px;
    border: none !important;
    height: 36px !important;
    margin-top: 14px;
    white-space: nowrap;
    line-height: initial !important;

    .week {
      display: flex;
      pointer-events: none;
      align-items: center;
      padding-left: 5px;
      white-space: normal;
    }

    .firstWeek {
      border-radius: 4px 0px 0px 4px;
    }

    .lastWeek {
      border-radius: 0px 4px 4px 0px;
    }

    .singleWeek {
      border-radius: 4px;
    }

    .weekLoadWrapper {
      max-width: calc(100% - 5px);
    }

    .workTimeHours {
      font-family: $typography-tertiary-ubuntu-regular-font-family;
      font-size: 14px;
    }
  }

  .subItem {
    height: 28px !important;

    .week {
      display: flex;
      border-right: none;
      align-items: center;
      padding-left: 5px;
    }

    .workTimeHours {
      font-family: $typography-tertiary-ubuntu-regular-font-family;
      font-size: 12px;
    }
  }

  .newItem {
    height: 28px !important;
    border-radius: 4px 0px 0px 4px;

    &::after {
      content: '';
      position: absolute;
      margin-top: 4px;
      right: 4px;
      width: 2px;
      height: calc(100% - 8px);
      border-radius: 1px;
      background-color: $color-white;
      opacity: 0.3;
    }
  }

  .emptyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5%;

    p {
      font-family: $typography-primary-light-font-family;
      font-size: 20px;
      color: $color-gray-2;
    }
  }

  .cursorWrapper {
    position: absolute;
    height: 100%;
    transform: translateX(-50%);
    animation-duration: 0.2s;
    animation-name: appear;
    cursor: col-resize;

    #cursor {
      height: 100%;
    }

    @keyframes appear {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .loading {
    width: 1000px;
  }
}
