@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .textInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px, 0px, 0px, 0px;
    gap: 8px;

    .title {
      color: $color-head-1;
      font-family: $typography-primary-extra-light-font-family;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
    .description {
      color: $color-gray-dark;
      line-height: 24px;
      font-size: $typography-primary-font-size;
      font-weight: $typography-primary-font-weight;
    }
  }

  .FieldsContainer {
    display: flex;
    gap: 24px;

    .PhotoContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .textPhotoContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: $color-gray-dark;

        .photoTitle {
          size: $typography-primary-font-size;
          font-family: $typography-tertiary-ubuntu-regular-font-family;
        }

        .photoDescription {
          font-family: $typography-tertiary-ubuntu-light-font-family;
          size: $typography-primary-font-size;
        }
      }
      .uploadContainer {
        display: flex;
        align-items: center;
        gap: 24px;

        .avatar {
          height: 150px;
          width: 150px;
          font-size: $typography-header-m-font-size;
          font-family: $typography-primary-light-font-family;
        }

        .upload {
          font-family: $typography-secondary-light-font-family;
          font-weight: $typography-secondary-font-weight;
          background-color: $color-primary;
          color: $color-white;
          min-width: 150px;
          min-height: 42px;
        }
      }
    }

    .inputsContainer {
      display: flex;
      flex-direction: column;
      width: 300px;
      margin-left: 5%;

      .label {
        font-family: $typography-tertiary-ubuntu-regular-font-family;
        font-weight: $typography-secondary-font-weight;
        color: $color-gray-dark;
        font-size: $typography-primary-font-size !important;
      }

      .input {
        font-size: $typography-primary-font-size;
        font-family: $typography-tertiary-ubuntu-regular-font-family;
      }
    }
  }
}
