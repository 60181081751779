@import '../../styles';

.search {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 16px;
}

.hiddenBase {
  ::placeholder {
    color: transparent !important;
  }
}

.openBackground {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: calc(100% - 56px);
  margin-top: 56px;
  border-radius: 0 !important;
  background-color: $color-black-text;
  opacity: 0.4;

  @media screen and (min-width: 600px) {
    min-height: calc(100% - 70px);
    margin-top: 70px;
  }
}

.closeBackground {
  display: none;
}

.font {
  font-family: $typography-tertiary-ubuntu-light-font-family !important;
  font-size: $typography-primary-font-size !important;
  color: $color-black-text !important;

  ::placeholder {
    font-family: $typography-tertiary-ubuntu-regular-font-family !important;
    color: $color-black-text !important;
    opacity: 0.42;
  }
}

.css-yz9k0d-MuiInputBase-input {
  width: 100vh !important;
}
