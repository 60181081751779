@import 'src/styles';

.box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
  font-size: $typography-header-xs-font-size;
  min-width: 430px;
  margin-bottom: 5px;
  .font {
    font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
    font-size: $typography-secondary-font-size;
  }
}

.buttonBox {
  display: flex;
  flex-direction: row;
  margin: 9% 0 3% 40%;
  justify-content: space-around;

  .buttonWithoutBorders {
    color: $color-primary;
    font-family: $typography-secondary-regular-font-family !important;
  }

  .inviteButton {
    background-color: $color-primary;
    font-family: $typography-secondary-regular-font-family !important;
    color: $color-white;
    height: 10%;
    padding: 2% 13% 2% 13%;
  }
  .inviteButton:hover {
    background-color: $color-primary !important;
    opacity: 0.7;
  }
}

.checkboxArea {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 5px;
    margin-bottom: 0 !important;
  }

  input,
  label {
    cursor: pointer;
  }

  #checkbox {
    color: $color-gray-dark;

    &.Mui-checked {
      color: $color-primary;
    }
  }
}

input[type='checkbox'] {
  background-color: $color-primary !important;
}

.nameContainer {
  margin-bottom: 20px;
}
