@import 'src/styles';

.pageName {
  font-family: $typography-primary-light-font-family, sans-serif !important;
  margin-bottom: 30px;
}

.textFieldBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
  font-size: $typography-header-xs-font-size;
  min-width: 475px;

  .font {
    font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
    font-size: $typography-secondary-font-size;
  }

  .gridBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .setButton {
      font-family: $typography-secondary-light-font-family;
    }

    .colorRed {
      color: $color-red2 !important;
      padding: 2px;
    }

    .colorBlue {
      color: $color-blue;
    }

    .colorOrange {
      color: $color-primary;
    }

    .colorLightGreen {
      color: $color-green;
    }

    .colorGreen {
      color: $color-green-6;
    }

    .colorPurple {
      color: $color-purple-dark;
    }

    .colorLightPurple {
      color: $color-purple-3;
    }

    .colorIndigo {
      color: $color-indigo;
    }

    .colorYellow {
      color: $color-yellow;
    }
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: $typography-primary-light-font-family, sans-serif !important;
  margin-bottom: 15px;

  .cancel {
    color: $color-primary;
    background-color: $color-white;
    min-width: 25%;
    font-family: $typography-primary-light-font-family, sans-serif !important;
  }

  .save {
    background-color: $color-primary;
    color: $color-white;
    min-width: 25%;
    font-family: $typography-primary-light-font-family, sans-serif !important;
  }
}
