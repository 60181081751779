@import 'src/styles';

.wrapper {
  background-color: $color-background;
  overflow-x: hidden;
  min-height: 115vh;
  font-family: $typography-font-family;

  .upperLevel {
    display: flex;
    flex-direction: column;
    margin: 20px 24px;
    gap: 20px;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-family: $typography-primary-extra-light-font-family;
        width: max-content;
        height: 100%;
        border-radius: 4%;
        font-size: $typography-subtitle-font-size;
        font-weight: $typography-title-font-weight;
      }
    }
  }
}
