@import 'src/styles';

.pageName {
  font-family: $typography-primary-light-font-family, sans-serif !important;
}

.customGridBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
}

.gridBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
}

.choosenField {
  color: $color-orange-main !important;
}

.textFieldBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
  font-size: $typography-header-xs-font-size;
  min-width: 400px;

  .font {
    font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
    font-size: $typography-primary-font-size;
  }

  .chipColor {
    border-radius: 16px;
    background-color: $color-purple-1;
    color: $color-white;
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: $typography-primary-light-font-family, sans-serif !important;
  margin-bottom: 15px;

  .cancel {
    color: $color-primary;
    background-color: $color-white;
    min-width: 25%;
    font-family: $typography-primary-light-font-family, sans-serif !important;
  }

  .save {
    background-color: $color-primary-light;
    color: $color-white;
    min-width: 25%;
    font-family: $typography-primary-light-font-family, sans-serif !important;
  }
}

.colorBlack {
  color: $color-black-text !important;
}

.colorRed {
  color: $color-red !important;
}

.colorBlue {
  color: $color-blue-main !important;
}

.colorPurple {
  color: $color-purple-dark !important;
}
