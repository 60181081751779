@import '../../../styles';

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 0 40px 20px;

  .closeItem {
    color: $color-primary;
    cursor: pointer;
  }

  .headerTitle {
    font-family: $typography-primary-regular-font-family, sans-serif;
    color: $color-primary;
    font-size: $typography-title-font-size;
  }
}

.form {
  .label {
    font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif;
    font-size: $typography-primary-font-size;
    color: $color-gray-dark;
    width: 120px;
    margin-right: 20px;
    padding: 0;
  }

  .selectedLabel {
    color: $color-primary;
    opacity: 1;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .calendar {
      width: 50%;
      margin-left: 35px;
    }

    .smallLabel {
      font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif;
      font-size: $typography-secondary-font-size;
      color: $color-gray-dark;
      padding-left: 10px;
    }

    .valuteItem {
      display: flex;
      width: max-content;
      margin-right: 20px;
      align-items: center;

      .valuteIcon {
        color: $color-gray-2;
        margin-right: 2px;
      }

      .exchangeRate {
        width: 87px;
        border: none !important;
        background-color: transparent;
        font-family: $typography-secondary-light-font-family, sans-serif !important;
        font-size: $typography-primary-font-size !important;
      }

      .valute {
        width: 87px;
        border: none !important;
        background-color: transparent;
        font-family: $typography-secondary-light-font-family, sans-serif !important;
        font-size: $typography-primary-font-size !important;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
      }
    }

    .formItem {
      width: 300px !important;
      border: none !important;
      background-color: transparent;
      border-bottom: 1px solid $color-gray-1 !important;
      font-family: $typography-secondary-light-font-family, sans-serif !important;
      color: $color-head-1 !important;
      font-size: $typography-primary-font-size !important;
    }

    .hoursItem {
      width: 50px !important;
      border: none !important;
      background-color: transparent;
      border-bottom: 1px solid $color-gray-1 !important;
      font-family: $typography-secondary-light-font-family, sans-serif !important;
      color: $color-head-1 !important;
      font-size: $typography-primary-font-size !important;
    }
  }
}

.buttons {
  display: flex;
  padding-top: 20px;

  .button {
    font-family: $typography-secondary-regular-font-family, sans-serif;
    padding: 10px;
    color: $color-primary;
    background: transparent;
    border: none;
    font-size: $typography-header-font-size;

    &:hover {
      background-color: $color-shades-12p;
    }
  }

  .fullButton {
    font-family: $typography-secondary-regular-font-family, sans-serif;
    color: $color-white;
    background-color: $color-primary;
    border: none;
    width: 150px;
    font-size: $typography-header-font-size;
    border-radius: 4px;

    &:hover {
      background-color: $color-shades-50p;
    }
  }
}
