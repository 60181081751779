@import 'src/styles';

.projectButton {
  margin-right: 100px !important;
  font-family: $typography-font-family !important;
  font-size: $typography-primary-font-size !important;
  text-transform: none !important;
  display: flex !important;
  gap: 10px !important;
}

.projectButtonEdit {
  font-family: $typography-tertiary-ubuntu-light-font-family !important;
  font-size: $typography-secondary-font-size !important;
  text-transform: none !important;
  display: flex !important;
  gap: 10px !important;

  .buttonActive {
    border-radius: 4px;
    border: 1px solid $color-box-shadow-1;
  }
}

.wrapper {
  background: $color-gray-5;
  border-radius: 4px;
  padding-bottom: 10px;

  z-index: 1300;

  input {
    font-family: $typography-secondary-light-font-family;
    border-radius: 4px;
    border: 1px solid $color-box-shadow-1;
    padding: 12px 10px;
    width: 320px;
    background-color: $color-background;
  }

  ul {
    max-height: 200px;
    list-style-type: none;

    li {
      padding-bottom: 0;
    }
  }

  .projectItem {
    margin: 0;
    cursor: pointer;
    display: flex;
    font-family: $typography-primary-light-font-family;
    font-size: $typography-primary-font-size;

    transition: background-color 0.2s linear;

    &:hover {
      //background-color: rgba(243, 109, 37, 0.08);
    }

    &:before {
      display: none;
    }

    button {
      font-family: $typography-tertiary-ubuntu-light-font-family;
      font-size: $typography-secondary-font-size;
      text-transform: none;
    }

    .accordion {
      width: 100%;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      box-shadow: none;

      .accordionDetails {
        background-color: white;
        z-index: 99;

        button {
          padding-left: 37px;

          display: flex;
          justify-content: left;
        }

        .taskButton {
          justify-content: space-between;
          align-items: center;
          color: black;
          height: 45px;

          button {
            scale: 0.8;
            padding: 8px;
            margin-right: 1px;
          }

          svg {
            padding: 0;
          }
        }
      }
    }
  }

  .bottomBox {
    border-top: 1px solid $color-box-shadow-1;
    display: flex;
    align-items: stretch;

    button {
      font-family: $typography-primary-light-font-family;
      font-size: $typography-primary-font-size;
      text-transform: none;
      flex-grow: 1;

      padding: 7px 10px;

      display: flex;
      align-items: center;
      justify-content: left;
      text-align: left;
      gap: 10px;

      border-top-left-radius: 0;
      border-top-right-radius: 0;

      svg {
        height: 24px;
        width: auto;
      }

      span:nth-child(1) {
        margin-left: 5px;
      }
    }
  }
}
