@import 'styles';

%checkbox {
  font-family: $typography-secondary-light-font-family;
  font-weight: $typography-secondary-font-weight;
  background-color: $color-white !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  height: 30px;
  width: 100%;
  min-width: 0px !important;
}
.notChecked {
  @extend %checkbox;
  color: $color-gray-2 !important;
  border-color: $color-gray-2 !important;
}

.checked {
  @extend %checkbox;
  color: $color-primary !important;
  border-color: $color-primary !important;
}
