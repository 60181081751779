@import 'styles';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .textInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px, 0px, 0px, 0px;
    gap: 8px;

    .title {
      color: $color-head-1;
      font-family: $typography-primary-extra-light-font-family;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
    .description {
      color: $color-gray-dark;
      line-height: 24px;
      font-size: $typography-primary-font-size;
      font-weight: $typography-primary-font-weight;
      letter-spacing: 0.15px;
    }
  }

  .FieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 300px;

    .inputContainer {
      .label {
        font-family: $typography-tertiary-ubuntu-regular-font-family;
        font-weight: $typography-secondary-font-weight;
        color: $color-gray-dark;
        font-size: $typography-primary-font-size !important;
      }

      .input {
        font-size: $typography-primary-font-size;
        font-family: $typography-tertiary-ubuntu-regular-font-family;
      }
    }
  }

  .buttonBox {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .editCancel {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      color: $color-primary;
      background-color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }

    .cancel {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      color: $color-gray-2;
      background-color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }

    .editSave {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      background-color: $color-primary;
      color: $color-white;
      min-width: 150px;
      min-height: 42px;
    }

    .save {
      font-family: $typography-secondary-light-font-family;
      font-weight: $typography-secondary-font-weight;
      background-color: $color-gray-4;
      color: $color-gray-2;
      min-width: 150px;
      min-height: 42px;
    }
  }
}
