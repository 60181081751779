@import 'src/styles';

.avatar {
  position: relative;
  bottom: 2px;
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  overflow: hidden;
  background-color: #323232;

  .logo {
    width: 24px;
    height: 24px;

    path {
      fill: white;
      fill-opacity: 1;
    }
  }
}
