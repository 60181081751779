@import 'src/styles';

.wrapper {
  background-color: white;
  width: 280px;
  padding: 14px 18px;

  border-radius: 4px;
  border: 1px solid $color-box-shadow-1;

  z-index: 1300;
}
