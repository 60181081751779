@import 'src/styles/index';

.member {
  display: flex;
  flex-direction: column;

  .userItem {
    height: 100%;
    display: flex;
    background-color: $color-white;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .userInfo {
      display: flex;
      gap: 15px;
    }

    .textItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;

      .name {
        font-family: $typography-tertiary-ubuntu-light-font-family;
        font-size: $typography-secondary-font-size;
        margin: 0;
      }

      .expertize {
        color: $color-gray-dark;
        font-family: $typography-tertiary-ubuntu-light-font-family;
        font-size: $typography-accent-font-size;
        margin: 0;
      }
    }

    .star {
      color: $color-accent;
    }

    .avatar {
      font-family: $typography-tertiary-ubuntu-light-font-family;
    }

    .buttonArrow {
      display: flex;
      color: $color-gray-2;
    }

    .buttonDot,
    .buttonArrow {
      padding: 0;
      border: none;
      height: max-content !important;
      min-height: 10px !important;
      background-color: transparent;
      cursor: pointer;

      :hover {
        border-radius: 100px;
        background-color: $color-orange-light-shades-8p;

        :last-child {
          color: $color-orange-5 !important;
        }
      }

      .dot {
        color: $color-gray-4;
        display: flex;
        align-items: center;

        :hover {
          border-radius: 100px;
          background-color: $color-orange-light-shades-8p;
          color: $color-orange-5 !important;
        }
      }
    }
  }
}
