@import 'src/styles';

.box {
  background-color: $color-gray-extra-light !important;
  display: flex;
  align-items: center;
  margin: 2% 4%;
  padding-right: 16px;
  padding-left: 11px;
  border-radius: 4px;
  border: 1px solid $color-box-shadow-5;
  height: 20%;

  .minus {
    padding: 5px;
    margin: 0;
    border: 0;
    background-color: $color-background;
    color: $color-secondary;
    border-radius: 4px;
    margin-right: 5px;
  }

  .buttonItem,
  .calendarActive {
    padding: 5px;
    margin: 0;
    border: 0;
    background-color: $color-background;
    color: $color-secondary;
    font-family: $typography-tertiary-ubuntu-regular-font-family;
    font-size: $typography-secondary-font-size;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: max-content;
    width: max-content;
  }

  .calendarActive {
    background-color: #f2e6de !important;
    color: $color-primary !important;
  }

  .buttonItem:active {
    background-color: #f2e6de !important;
    color: $color-primary !important;
  }

  .tabs {
    display: flex;
    height: 100%;
    margin-top: 0.5%;
    width: max-content;
    padding: 0 !important;
    color: $color-primary;
    margin-left: 5px;

    > div {
      display: flex;
    }

    .calendarButtons {
      display: flex;
    }

    .calendarButtons {
      display: flex;
    }

    .itemButton {
      width: max-content !important;
      min-width: max-content !important;
      padding-right: 10px;
      padding-left: 10px;
    }

    .tab {
      color: $color-black-text;
      opacity: 0.6;
      font-family: $typography-tertiary-ubuntu-regular-font-family;
      text-transform: none;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;
    margin-left: auto;
    border-radius: 4px;

    ::placeholder {
      font-family: $typography-tertiary-ubuntu-light-font-family;
      font-size: $typography-secondary-font-size;
      z-index: 1 !important;
    }

    .font {
      font-family: $typography-tertiary-ubuntu-light-font-family !important;
      font-size: $typography-secondary-font-size !important;
      color: $color-black-text !important;
      opacity: 0.6;
    }

    .buttonsZoom,
    .buttonsArrow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      button:last-child {
        margin-left: 2px;
      }
    }
    .buttonsArrow,
    .buttonCalendar {
      margin-left: 5px;
    }
  }

  .removeItem {
    display: none !important;
  }
}

.boxWithoutBorder {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}
