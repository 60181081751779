@import 'src/styles';
@import 'src/components/modals/AddTaskModal/AddTaskModal.module';

.dateBlock {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: $typography-primary-font-size;
    font-weight: $typography-primary-font-weight;
  }
}

.error {
  font-size: $typography-primary-font-size;
  font-weight: $typography-primary-font-weight;
  color: red;

  display: flex;
  width: 100%;
  justify-content: right;
}
