@import 'src/styles';

.wrapper {
  width: 100%;
  height: 100%;

  .container {
    border-radius: 4px 4px 0 0;
    border-top: 1px solid $color-gray-4;
    border-left: 1px solid $color-gray-4;
    border-right: 1px solid $color-gray-4;
    background-color: $color-white;

    .table {
      overflow: hidden;
    }

    .tableCellHead {
      background-color: $color-gray-extra-light;
      font-family: $typography-tertiary-ubuntu-regular-font-family, sans-serif;
      white-space: nowrap;
    }

    .tableBody {
      height: auto;
    }

    .tableRow {
      cursor: pointer;
    }

    .typography {
      font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
    }

    .projectCell {
      padding-left: 23px;
      //min-width: 100px;
      //max-width: 180px;
      white-space: nowrap;
    }

    .descriptionCell {
      width: 100%;
    }

    .billableCell {
      width: 60px;
    }

    .nameCell {
      white-space: nowrap;
    }

    .timeCell {
      white-space: nowrap;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span:nth-child(2) {
        color: #9e9e9e;
      }
    }

    .durationCell {
      max-width: 80px;

      .duration {
        font-family: $typography-tertiary-ubuntu-medium-font-family;
      }
    }
  }
}
