@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .textInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px, 0px, 0px, 0px;
    gap: 8px;

    .title {
      color: $color-head-1;
      font-family: $typography-primary-extra-light-font-family;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
    .description {
      color: $color-gray-dark;
      line-height: 24px;
      font-size: $typography-primary-font-size;
      font-weight: $typography-primary-font-weight;
      letter-spacing: 0.15px;
    }
  }

  .FieldsContainer {
    display: flex;
    gap: 40px;

    .column {
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 21px;

      .inputContainer {
        .input {
          font-size: $typography-primary-font-size;
          font-family: $typography-tertiary-ubuntu-light-font-family;
          font-weight: $typography-primary-font-weight;
          height: 39px;
        }

        .label {
          font-family: $typography-tertiary-ubuntu-regular-font-family;
          font-weight: $typography-secondary-font-weight;
          color: $color-gray-dark;
          font-size: $typography-primary-font-size !important;
        }
        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          .checkboxArea {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
              margin-left: 5px;
              margin-bottom: 0 !important;
            }

            input,
            label {
              cursor: pointer;
            }

            #checkbox1,
            #checkbox2,
            #checkbox3,
            #checkbox4,
            #checkbox5 {
              color: $color-gray-dark;

              &.Mui-checked {
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
}
