@import 'src/styles';

.record {
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid $color-box-shadow-1;
  background: var(--light-background-paper, #fff);

  %row-base {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 65px;
  }

  .recordRowFirst {
    @extend %row-base;
    justify-content: space-between;
    flex-shrink: 0;
    padding-left: 20px;
    padding-right: 10px;

    .recordInput {
      flex-grow: 1;
      padding: 5px 10px;
      border-radius: 4px;
      margin-right: 18px;

      border: 1px solid transparent;
      transition: border-color 0.2s linear;

      &:hover,
      &:focus-within {
        border-color: $color-box-shadow-1;
      }

      div {
        width: 100%;
      }

      input {
        font-family: $typography-primary-light-font-family;
        font-size: $typography-secondary-font-size;
      }
    }
  }

  .recordRowSecond {
    @extend %row-base;
    background-color: #f5f5f5;
    border-top: 1px solid $color-box-shadow-1;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px 20px 10px 10px;
    justify-content: end;
    gap: 20px;

    .startButton {
      font-family: $typography-font-family;
      color: $color-white;

      .startButtonRed {
        color: $color-red;
      }
    }

    .timePickerBlock {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .recordTimer {
      width: 70px;
      text-align: center;
      font-family: $typography-primary-regular-font-family;
      color: $color-black-text;
    }

    .manualCurrentDay {
      font-family: $typography-primary-light-font-family;
      text-align: center;
      width: 95px;

      display: flex;
      justify-content: center;
      gap: 2px;

      .nextDayIndicator {
        font-family: $typography-primary-regular-font-family;
        opacity: 0.5;
        scale: 0.7;
        transform: translateY(-8px);
      }
    }
  }
}
