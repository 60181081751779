@import 'styles';

.wrapper {
  border-radius: 4px;
  background-color: transparent;
  width: 463px;
  font-family: $typography-primary-extra-light-font-family;
  font-size: $typography-title-font-size;
  font-weight: $typography-title-font-weight;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    margin-bottom: 8%;
  }

  .font {
    font-family: $typography-tertiary-ubuntu-light-font-family;
    color: $color-black-text;
    opacity: 0.87;
  }

  .close {
    color: $color-primary;
  }

  .name {
    color: $color-black-text;
    opacity: 0.6;
    font-family: $typography-tertiary-ubuntu-light-font-family;
    font-size: $typography-primary-font-size;
    font-weight: $typography-primary-font-weight;
    white-space: nowrap;
  }

  .icon,
  .iconConvert {
    color: $color-black-text;
    opacity: 0.38;
    width: 20%;
    max-height: 16px;
  }
  .icon {
    margin-right: 10px;
  }
  .iconConvert {
    margin-right: 5px;
  }

  .dollarIcon {
    width: 23%;
  }
  .dollarIcon,
  .timeIcon {
    color: $color-black-text;
    opacity: 0.38;
    margin-right: 5px;
  }

  .fullTextFieldContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4%;
    margin-right: 3%;
    margin-top: 6%;

    .fullTextField {
      width: 73%;
    }

    .textCopy {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .rateTextField {
      width: 19%;
    }

    .copyIcon {
      max-width: 30px;
      max-height: 30px;
      cursor: pointer;
    }
  }

  .minTextFieldContainer {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 6%;

    .nameMinTextField {
      width: 10%;
      color: $color-black-text;
      opacity: 0.6;
      font-family: $typography-tertiary-ubuntu-light-font-family;
      font-size: $typography-primary-font-size;
      font-weight: $typography-primary-font-weight;
      white-space: nowrap;
    }

    .minTextField {
      width: 20%;
      margin-left: 16%;
    }

    .info {
      font-size: $typography-secondary-font-size;
      position: relative;
      color: $color-black-text;
      opacity: 0.6;
      font-family: $typography-tertiary-ubuntu-light-font-family;
      font-weight: $typography-primary-font-weight;
    }
  }

  .workDaysContainer {
    display: flex;
    margin-top: 6%;
    align-items: center;
    gap: 8%;

    .buttonContainerWorkDays {
      display: flex;
      gap: 1%;
    }
  }

  .buttonBox {
    display: flex;
    margin: 9% 0 3% 0;
    justify-content: space-around;

    .buttonWithoutBorders {
      color: $color-primary;
      font-family: $typography-secondary-regular-font-family !important;
    }

    .save {
      background-color: $color-primary;
      font-family: $typography-secondary-regular-font-family !important;
      color: $color-white;
      height: 10%;
      padding: 2% 13% 2% 13%;
    }
  }
}
