@import 'src/styles';

.categoryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 5px;

  div {
    span {
      margin-left: 15px;
      font-family: $typography-primary-regular-font-family;
      font-size: $typography-primary-font-size;
      color: $color-black-text;
    }
  }
}
