@import 'src/styles';

.wrapper {
  border-radius: 4px;
  min-width: 463px;
  font-family: $typography-primary-extra-light-font-family;
  font-size: $typography-title-font-size;
  font-weight: $typography-title-font-weight;

  .inputWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .calendar {
      width: 50%;
      margin-left: 35px;
    }

    .smallLabel {
      font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif;
      font-size: $typography-secondary-font-size;
      color: $color-gray-dark;
      padding-left: 10px;
    }

    .valuteItem {
      display: flex;
      width: max-content;
      margin-right: 20px;
      align-items: center;

      .valuteIcon {
        color: $color-gray-2;
        margin-right: 2px;
      }

      .exchangeRate {
        width: 87px;
        border: none !important;
        background-color: transparent;
        font-family: $typography-secondary-light-font-family, sans-serif !important;
        font-size: $typography-primary-font-size !important;
      }

      .valute {
        width: 87px;
        border: none !important;
        background-color: transparent;
        font-family: $typography-secondary-light-font-family, sans-serif !important;
        font-size: $typography-primary-font-size !important;
      }
    }

    .formItem {
      width: 300px !important;
      border: none !important;
      background-color: transparent;
      border-bottom: 1px solid $color-gray-1 !important;
      font-family: $typography-secondary-light-font-family, sans-serif !important;
      color: $color-head-1 !important;
      font-size: $typography-primary-font-size !important;
    }

    .hoursItem {
      width: 50px !important;
      border: none !important;
      background-color: transparent;
      border-bottom: 1px solid $color-gray-1 !important;
      font-family: $typography-secondary-light-font-family, sans-serif !important;
      color: $color-head-1 !important;
      font-size: $typography-primary-font-size !important;
    }
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    margin-bottom: 8%;
  }

  .close {
    color: $color-primary;
  }

  .font {
    font-family: $typography-tertiary-ubuntu-light-font-family;
    color: $color-black-text;
    opacity: 0.87;
  }

  .label {
    color: $color-black-text;
    opacity: 0.6;
    font-family: $typography-tertiary-ubuntu-light-font-family;
    font-size: $typography-primary-font-size;
    font-weight: $typography-primary-font-weight;
    white-space: nowrap;
  }

  .selectedName {
    color: $color-primary;
    opacity: 1;
  }

  .gridBox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .colorRed {
    color: $color-red2 !important;
  }

  .colorBlue {
    color: $color-blue;
  }

  .colorOrange {
    color: $color-primary;
  }

  .colorLightGreen {
    color: $color-green;
  }

  .colorGreen {
    color: $color-green-6;
  }

  .colorPurple {
    color: $color-purple-dark;
  }

  .colorLightPurple {
    color: $color-purple-3;
  }

  .colorIndigo {
    color: $color-indigo;
  }

  .colorYellow {
    color: $color-yellow;
  }

  .buttonBox {
    display: flex;
    margin: 9% 0 3% 0;
    justify-content: flex-end;
    gap: 10px;

    .cancel {
      color: $color-primary;
      font-family: $typography-secondary-regular-font-family !important;
      padding: 2% 10% 2% 10%;
    }

    .delete {
      color: grey;
      font-family: $typography-secondary-regular-font-family !important;
      padding: 2% 10% 2% 10%;

      transition: color 0.2s linear;

      &:hover {
        color: $color-shades-red;
      }
    }

    .add {
      font-family: $typography-secondary-regular-font-family !important;
      color: $color-white;
      height: 10%;
      padding: 2% 13% 2% 13%;
    }
  }
}
