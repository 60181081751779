@import '../../../styles';

.userItem {
  height: 100%;
  display: flex;
  background-color: $color-white;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .name {
      font-family: $typography-tertiary-ubuntu-light-font-family;
      font-size: 1rem;
      margin: 0;
    }

    .role {
      color: $color-gray-dark;
      font-size: $typography-accent-font-size;
      margin: 0;
    }
  }

  .buttonArrow {
    display: flex;
    color: $color-gray-2;
  }

  .buttonDot,
  .buttonArrow {
    padding: 0;
    border: none;
    height: max-content !important;
    min-height: 10px !important;
    background-color: transparent;
    cursor: pointer;

    :hover {
      border-radius: 100px;
      background-color: $color-orange-light-shades-8p;

      :last-child {
        color: $color-orange-5 !important;
      }
    }

    .dot {
      color: $color-gray-4;
      display: flex;
      align-items: center;

      :hover {
        border-radius: 100px;
        background-color: $color-orange-light-shades-8p;
        color: $color-orange-5 !important;
      }
    }
  }
}
