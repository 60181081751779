@import 'src/styles';

.container {
  border-radius: 4px;
  border: 1px solid $color-box-shadow-1;
  margin-top: 20px;

  %ubuntu-font {
    font-family: $typography-tertiary-ubuntu-light-font-family;
    font-size: $typography-secondary-font-size;
  }

  %time-font {
    font-family: $typography-primary-regular-font-family;
    font-size: $typography-primary-font-size;
    color: $color-black-text;
  }

  .head {
    @extend %ubuntu-font;
    background-color: #f5f5f5;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);

    .leftBlock {
      display: inline-flex;
      align-items: center;
      gap: 4px;

      .bulkButton {
        @extend %ubuntu-font;
        text-transform: none;
      }
    }

    .headTimeBlock {
      @extend %ubuntu-font;
      display: flex;
      align-items: center;
      gap: 10px;
      color: rgba(0, 0, 0, 0.6);
    }

    .timeText {
      @extend %time-font;
      margin-left: 5px;
    }
  }

  .recordBlock {
    @extend %ubuntu-font;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 10px 20px;
    height: 54px;
    border-top: 1px solid $color-box-shadow-1;

    .titleBlock {
      display: inline-flex;
      gap: 15px;

      transition: opacity 0.5s;
    }

    .projectText {
      display: flex;
      gap: 7px;
    }

    .timeText {
      @extend %time-font;
      text-align: center;
      width: 70px;
    }

    span {
      display: flex;
      align-items: center;
    }

    div {
      @extend %ubuntu-font;
    }

    .timeGroup {
      align-items: center;
      justify-content: space-between;
      width: 260px;
      display: flex;
      padding: 0 12px;

      div:first-of-type {
        align-items: center;
        justify-content: space-between;
        display: flex;

        div:nth-child(2) {
          padding-bottom: 2px;
          padding-left: 3px;
          padding-right: 10px;
        }
      }
    }
  }
}
