@import 'src/styles';

.box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
  font-size: $typography-header-xs-font-size;
  min-width: 430px;

  .tipContainer {
    font-family: $typography-tertiary-ubuntu-light-font-family, sans-serif !important;
    font-size: $typography-primary-font-size;
    color: $color-black-text;
    opacity: 0.6;
    line-height: 24px;
    margin-bottom: 20px;
    max-width: 409px;
  }
}

.name {
  color: $color-black-text;
  opacity: 0.6;
  font-family: $typography-tertiary-ubuntu-light-font-family;
  font-size: $typography-primary-font-size;
  font-weight: $typography-primary-font-weight;
  white-space: nowrap;
}

.selectedName {
  color: $color-primary;
  opacity: 1;
}

.nameContainer {
  margin-bottom: 20px;
  align-items: center;
}

.close {
  color: $color-primary;
}

.buttonBox {
  display: flex;
  margin: 9% 0 3% 0;
  justify-content: flex-end;
  gap: 10px;

  .cancel {
    color: $color-primary;
    font-family: $typography-secondary-regular-font-family !important;
    padding: 2% 10% 2% 10%;
  }

  .add {
    background-color: $color-primary;
    font-family: $typography-secondary-regular-font-family !important;
    color: $color-white;
    height: 10%;
    padding: 2% 13% 2% 13%;
  }
}
