@import '../../styles';

.header {
  position: static !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: $color-background;

  .headerSection {
    display: flex;
    align-items: center;
    margin: 0 2.5% 0 1.8%;
    gap: 40px;

    .icon {
      color: $color-black-text;
      opacity: 0.7;
    }
    .iconPaid {
      color: $color-black-text;
      opacity: 0.4;
    }

    .headerItem {
      .rate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3%;
        font-family: $typography-tertiary-ubuntu-light-font-family;
        font-weight: $typography-primary-font-weight;
        font-size: $typography-header-font-size;
      }
    }
  }
}

.searchListbox {
  z-index: 100;
  position: absolute;
  width: 100%;
  margin: 0;
  background-color: $color-background;

  overflow-y: scroll;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 9em;
    cursor: pointer;
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: calc(100% - 32px);
    height: 1px;
    margin: 0 16px;
    background-color: rgba(0, 0, 0, 0.12);
  }

  .searchOption {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 55px;
    margin: 8px 0;
    padding-left: 24px;
    font-family: $typography-tertiary-ubuntu-light-font-family;
    font-size: $typography-primary-font-size !important;
    transition: none;

    &::before {
      content: none;
    }

    &:hover {
      color: $color-black-text;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.avatar {
  cursor: pointer;
}
