@import 'src/styles/index';

.select {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px 8px;
  height: 44px;

  p {
    color: $color-primary;
  }

  div {
    div {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 0 !important;

      div {
        top: 0;
        right: 2.5px !important;

        .searchIcon {
          width: 20px;
          height: 20px;
          color: black;
          opacity: 0.38;
        }

        input {
          font-size: 14px;
          font-family: $typography-primary-light-font-family;
          height: 28px;
          padding: 1.5px 4px 1.5px 6px !important;
        }

        .buttonArrow {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 28px;
          height: 100%;
          border-radius: 0;
          border: 0;
          border-left: 1px $color-gray-4 solid;
          padding: 0;
          color: black;
          background-color: $color-gray-extra-light;

          .arrowIcon {
            opacity: 0.38;
          }

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}
