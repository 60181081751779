@import 'variables';

@mixin mobile($overwriteVal: null) {
  @if $overwriteVal {
    @media (max-width: #{$overwriteVal}) {
      @content;
    }
  } @else {
    @media (max-width: #{$bp-mobile}) {
      @content;
    }
  }
}

@mixin big-mobile($overwriteVal: null) {
  @if $overwriteVal {
    @media (max-width: #{$overwriteVal}) {
      @content;
    }
  } @else {
    @media (max-width: #{$bp-mobile-big}) {
      @content;
    }
  }
}

@mixin huge-mobile($overwriteVal: null) {
  @if $overwriteVal {
    @media (max-width: #{$overwriteVal}) {
      @content;
    }
  } @else {
    @media (max-width: #{$bp-below-tablet}) {
      @content;
    }
  }
}

@mixin tablet {
  @media (max-width: #{$bp-tablet}) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: #{$bp-tablet-small}) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: #{$bp-small-desktop}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$bp-desktop}) {
    @content;
  }
}

@mixin large-desktop($overwriteVal: null) {
  @if $overwriteVal {
    @media (max-width: #{$overwriteVal}) {
      @content;
    }
  } @else {
    @media (max-width: #{$bp-xl-desktop}) {
      @content;
    }
  }
}
