@import 'styles';

.wrapper {
  background-color: $color-background;
  overflow-x: hidden;
  max-width: 100%;

  .titleContainer {
    padding: 16px, 0px, 8px, 0px;

    .title {
      font-family: $typography-primary-extra-light-font-family;
      width: max-content;
      height: 100%;
      border-radius: 4%;
      font-size: $typography-subtitle-font-size;
      font-weight: $typography-title-font-weight;
    }
  }
}
