@import 'styles';

.nameBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.button {
  background-color: $color-primary !important;
  color: $color-white !important;
  width: 370px;
  margin: 10px auto !important;
  font-family: $typography-primary-light-font-family, sans-serif !important;
}

.button:hover {
  background-color: $color-primary-light !important;
}
